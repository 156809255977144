import React, { useState, useEffect, useContext } from "react";
import { ShoppingContext } from "../context/ShoppingContext";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Button,
  Typography,
  Avatar,
  Box,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const AddShoppingList = ({
  show,
  onClose,
  search,
  setSearch,
  searchResults,
}) => {
  const {
    shoppingList,
    addItemToShoppingList,
    removeItemFromShoppingList,
    fetchShoppingList,
  } = useContext(ShoppingContext);

  const [addedItems, setAddedItems] = useState(new Set());

  // Update the addedItems whenever shoppingList changes
  useEffect(() => {
    if (shoppingList && Array.isArray(shoppingList)) {
      const itemsSet = new Set(
        shoppingList
          .filter((item) => item && item.itemName)
          .map((item) => item.itemName)
      );
      setAddedItems(itemsSet);
    }
  }, [shoppingList]);

  // Helper function to handle adding an item
  const handleAddItem = async (merchantName, item) => {
    try {
      await addItemToShoppingList(merchantName, item);
      await fetchShoppingList();
    } catch (error) {
      console.error("Error adding item to shopping list:", error);
    }
  };

  // Helper function to handle removing an item
  const handleRemoveItem = (merchantName, item) => {
    if (merchantName && item) {
      removeItemFromShoppingList(merchantName, item);
      setAddedItems((prevSet) => {
        const updatedSet = new Set(prevSet);
        updatedSet.delete(item.itemName);
        return updatedSet;
      });
    } else {
      console.error("Invalid item or merchant name when removing item");
    }
  };

  return (
    <Dialog open={show} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Add Items</DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Search for an item across all our flyers.
        </Typography>

        {/* Search Input */}
        <TextField
          fullWidth
          variant="outlined"
          label="Search Items"
          placeholder="Name of ingredient?"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          sx={{ mb: 2 }}
        />

        {/* Search Results */}
        {searchResults && searchResults.length > 0 ? (
          <List>
            {searchResults.map((item, index) => (
              <ListItem
                key={index}
                divider
                secondaryAction={
                  addedItems.has(item.itemName) ? (
                    <IconButton
                      edge="end"
                      aria-label="remove"
                      onClick={() => handleRemoveItem(item.merchantName, item)}
                      color="success"
                    >
                      <CheckCircleIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      edge="end"
                      aria-label="add"
                      onClick={() => handleAddItem(item.merchantName, item)}
                      color="primary"
                    >
                      <AddCircleIcon />
                    </IconButton>
                  )
                }
              >
                <Avatar
                  src={
                    item.itemImageUrl
                      ? `/api/flyers/proxy-image?url=${encodeURIComponent(
                          item.itemImageUrl
                        )}`
                      : "https://via.placeholder.com/50"
                  }
                  alt={item.itemName}
                  sx={{ mr: 2 }}
                />
                <ListItemText
                  primary={item.itemName}
                  secondary={`${item.merchantName} - $${item.itemPrice}`}
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
            No results found. Try searching for another item.
          </Typography>
        )}

        {/* Done Button */}
        <Box sx={{ mt: 3, textAlign: "right" }}>
          <Button variant="contained" onClick={onClose}>
            I'm Done
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddShoppingList;
