import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { UserContext } from './context/UserContext';
import OAuthButtons from './OAuthButtons';
import {
  Box,
  TextField,
  Button,
  Typography,
  Stack,
  IconButton,
  InputAdornment
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from './BasePath/axiosInstance';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axiosInstance.post('/auth/login', { email, password });

      if (response?.data?.success) {
        localStorage.setItem('token', response?.data?.token);

        const res = await axiosInstance.get('/auth/me');
        const userResponse = res?.data;

        const userData = {
          fullName: userResponse?.fullName,
          email: userResponse?.email,
          avatar: userResponse?.avatar || 'https://via.placeholder.com/150',
        };

        localStorage.setItem('user', JSON.stringify(userData));
        setUser(userData); // Update UserContext
        toast.success('Login successful!', { position: 'top-right', autoClose: 2000 });

        navigate('/home');
      } else {
        toast.error(response?.data?.message || 'Login failed.');
        localStorage.clear();
      }
    } catch (error) {
      localStorage.clear();
      if (error.response?.status === 401) {
        toast.error('Login failed.', { position: 'top-right', autoClose: 3000 });
      } else if (error.response?.status === 404) {
        toast.error('Login failed.', { position: 'top-right', autoClose: 3000 });
      } else {
        toast.error('Login failed.', { position: 'top-right', autoClose: 3000 });
      }
    }
  };

  const handleOAuthLogin = (provider) => {
    window.location.href = `/api/auth/oauth/${provider}`;
  };

  return (
    <Stack width={'100%'} direction={'row'} justifyContent={'center'} my={4}>
      <Box
        sx={{
          width: 300,
          backgroundColor: '#f9f9ff',
          borderRadius: 2,
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          p: 3,
          textAlign: 'center',
        }}
      >
        <Typography variant="h6" sx={{ mb: 1 }}>
          Welcome back!
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          Log in to your account
        </Typography>
        <Stack spacing={2}>
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '10px',
              },
            }}
            required
          />
          <TextField
            label="Password"
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '10px',
              },
            }}
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <Button
          variant="contained"
          fullWidth
          sx={{
            mt: 3,
            backgroundColor: '#2979ff',
            color: '#fff',
            borderRadius: '10px',
            textTransform: 'none',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: '#1e5dcf',
            },
          }}
          onClick={handleLogin}
        >
          Login
        </Button>
        <Typography variant="body2" sx={{ mt: 2 }}>
          Don't have an account?{' '}
          <Link to="/signup" style={{ color: '#2979ff', textDecoration: 'none', fontWeight: 'bold' }}>
            Sign up here
          </Link>
        </Typography>
        <Typography variant="body2" sx={{ mt: 2 }}><Link style={{ color: '#2979ff', textDecoration: 'none', fontWeight: 'bold' }} to={'/forgetpassword'}>Forgot Password?</Link></Typography>
        <OAuthButtons handleOAuthLogin={handleOAuthLogin} />
        <ToastContainer />
      </Box>
    </Stack>
  );
};

export default Login;
