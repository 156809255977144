import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../components/BasePath/axiosInstance';

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const {id}= useParams();
    const handleTogglePasswordVisibility = () => {
      setShowPassword((prev) => !prev);
    };
  
    const handleToggleConfirmPasswordVisibility = () => {
      setShowConfirmPassword((prev) => !prev);
    };
    const navigate = useNavigate();
  const handlereset = async()=>{
      const res = await axiosInstance.post('/auth/resetpassword',{id,password});
      if(res?.data?.success)
      {
          navigate('/');
      }
  }

  return (
    <Box sx={{ maxWidth: 400, mx: 'auto', mt: 5, p: 3, boxShadow: 3, borderRadius: 2 }}>
    <Typography variant="h5" align="center" gutterBottom>
      Reset Password
    </Typography>
  
    <TextField
      fullWidth
      label="New Password"
      variant="outlined"
      margin="normal"
      type={showPassword ? 'text' : 'password'}
      value={password}
      onChange={(e) => setPassword(e.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleTogglePasswordVisibility} edge="end">
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
    <TextField
      fullWidth
      label="Confirm Password"
      variant="outlined"
      margin="normal"
      type={showConfirmPassword ? 'text' : 'password'}
      value={confirmPassword}
      onChange={(e) => setConfirmPassword(e.target.value)}
      error={password !== confirmPassword && confirmPassword.length > 0}
      helperText={password !== confirmPassword && confirmPassword.length > 0 ? 'Passwords do not match' : ''}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleToggleConfirmPasswordVisibility} edge="end">
              {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
    <Button fullWidth onClick={handlereset} variant="contained"  color="primary" sx={{ mt: 2 }}>
      Reset Password
    </Button>
  </Box>
  )
}

export default ResetPassword