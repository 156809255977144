import React, {  useState } from 'react';
import OAuthButtons from './OAuthButtons'; // Import the new component
import { Box, TextField, Button, Typography, Stack, CircularProgress } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import axiosInstance from './BasePath/axiosInstance';
import { useNavigate, Link } from 'react-router-dom'; // Import Link from react-router-dom

const Signup = () => {
  const [formData, setformData] = useState(null);
const [load,setload]=useState(false);


  const navigate = useNavigate();


  const handlechange = (e) => {
    const { name, value } = e.target;
    setformData((prev) => ({ ...prev, [name]: value }));
  };

  const SendOtp1 = async () => {
    try {
      if (!formData?.email) {
        toast('Email is required!', {
          position: 'top-right',
          autoClose: 4000,
        });
      } else {
        setload(true);
        const res = await axiosInstance.post('/auth/signup', {
          email: formData?.email,
        });
        console.log(res?.data)
        if (res?.data?.status) {
          setload(false)
          navigate(`/verify-otp/${formData?.email}`);
        }
        else{
          setload(false);
          alert("Email already in use!")
        }
      }
    } catch (error) {
      setload(false);
      alert("Email already in use!")
      console.error('Error during signup:', error);
    }
  };


  

  const handleOAuthLogin = (provider) => {
    // Redirect to backend OAuth route for the provider
    window.location.href = `/api/auth/oauth/${provider}`;
  };

  return (
    <Stack width={'100%'} direction={'row'} justifyContent={'center'} my={4}>
      <Box
        sx={{
          width: 300,
          backgroundColor: '#f9f9ff',
          borderRadius: 2,
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          p: 3,
          textAlign: 'center',
        }}
      >
        <Typography variant="h6" sx={{ mb: 1 }}>
          Hi, <span role="img" aria-label="wave">👋</span>
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          Create an account
        </Typography>
        <Stack spacing={2}>
          <TextField
            name="email"
            value={formData?.email}
            onChange={handlechange}
            label="Email"
            variant="outlined"
            fullWidth
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '10px',
              },
            }}
            required
          />
    
        </Stack>
        {load?<CircularProgress/>: <Button
          variant="contained"
          fullWidth
          sx={{
            mt: 3,
            backgroundColor: '#2979ff',
            color: '#fff',
            borderRadius: '10px',
            textTransform: 'none',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: '#1e5dcf',
            },
          }}
          onClick={SendOtp1}
        >
          Send OTP &gt;&gt;
        </Button>}
       
        <OAuthButtons handleOAuthLogin={handleOAuthLogin} />
        <Typography variant="body2" sx={{ mt: 2 }}>
          Already have an account?{' '}
          <Link to="/login" style={{ color: '#2979ff', textDecoration: 'none', fontWeight: 'bold' }}>
            Login
          </Link>
        </Typography>
        <ToastContainer />
      </Box>
    </Stack>
  );
};

export default Signup;
