import React, { useState, useEffect, useContext } from "react";
import { ShoppingContext } from "./context/ShoppingContext";
import axiosInstance from "./BasePath/axiosInstance";
import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  TextField,
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tabs,
  Tab,
  Card,
  CardContent,
} from "@mui/material";

const CompareFlyers = () => {
  const { addItem, removeItem, shoppingList } = useContext(ShoppingContext);
  const [stores, setStores] = useState([
    { name: "IGA", selected: true },
    { name: "Metro", selected: true },
    { name: "Adonis", selected: false },
    { name: "Maxi", selected: false },
    { name: "Provigo", selected: false },
  ]);
  const [searchItems, setSearchItems] = useState([]);
  const [category, setCategory] = useState("All");
  const [items, setItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [totalCost, setTotalCost] = useState(0);

  // Update total cost when shopping list changes
  useEffect(() => {
    const cost = shoppingList.reduce((sum, item) => sum + (item.price || 0), 0);
    setTotalCost(cost);
  }, [shoppingList]);

  const handleStoreChange = (index) => {
    const updatedStores = [...stores];
    updatedStores[index].selected = !updatedStores[index].selected;
    setStores(updatedStores);
  };

  const handleAddTag = (item) => {
    if (!searchItems.includes(item)) setSearchItems([...searchItems, item]);
  };

  const handleRemoveTag = (item) => {
    setSearchItems(searchItems.filter((i) => i !== item));
  };

  const fetchItems = async () => {
    const selectedStores = stores.filter((store) => store.selected).map((store) => store.name);
    const params = { stores: selectedStores, items: searchItems, category };

    try {
      const response = await axiosInstance.post("/flyers/compare", params);
      setItems(response.data.items);
      setCategories(["All", ...new Set(response.data.items.map((item) => item.category))]);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  const toggleItemSelection = (itemId, store) => {
    const existingItem = shoppingList.find((item) => item.id === itemId);
    if (existingItem && existingItem.store === store) {
      removeItem(itemId); // Remove if already selected
    } else {
      const item = items.find((item) => item.id === itemId);
      addItem({ id: itemId, store, price: item?.prices[store] || 0 });
    }
  };

  const renderItemsTable = () => {
    const filteredItems =
      category === "All" ? items : items.filter((item) => item.category === category);

    return (
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Item</TableCell>
              {stores
                .filter((store) => store.selected)
                .map((store) => (
                  <TableCell key={store.name} align="center">
                    {store.name}
                  </TableCell>
                ))}
              <TableCell align="center">Selected</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredItems.map((item) => (
              <TableRow key={item.id}>
                <TableCell>
                  <Box display="flex" alignItems="center" gap={2}>
                    <img src={item.image} alt={item.name} style={{ width: 50, height: 50 }} />
                    {item.name}
                  </Box>
                </TableCell>
                {stores
                  .filter((store) => store.selected)
                  .map((store) => (
                    <TableCell key={store.name} align="center">
                      {item.prices[store.name] ? (
                        <Box display="flex" flexDirection="column" alignItems="center">
                          <Typography variant="body2">
                            ${item.prices[store.name]}
                          </Typography>
                          <Button
                            variant="contained"
                            size="small"
                            color={
                              shoppingList.some(
                                (listItem) => listItem.id === item.id && listItem.store === store.name
                              )
                                ? "success"
                                : "primary"
                            }
                            onClick={() => toggleItemSelection(item.id, store.name)}
                          >
                            {shoppingList.some(
                              (listItem) => listItem.id === item.id && listItem.store === store.name
                            )
                              ? "✔"
                              : "+"}
                          </Button>
                        </Box>
                      ) : (
                        "N/A"
                      )}
                    </TableCell>
                  ))}
                <TableCell align="center">
                  ${shoppingList.find((listItem) => listItem.id === item.id)?.price || "0.00"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Compare Flyers
      </Typography>

      {/* Store Selection */}
      <Box display="flex" gap={2} flexWrap="wrap" mb={3}>
        {stores.map((store, index) => (
          <FormControlLabel
            key={store.name}
            control={
              <Checkbox
                checked={store.selected}
                onChange={() => handleStoreChange(index)}
              />
            }
            label={store.name}
          />
        ))}
      </Box>

      {/* Item Search */}
      <Card variant="outlined" sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Search Items
          </Typography>
          <TextField
            variant="outlined"
            placeholder="Enter an item..."
            fullWidth
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleAddTag(e.target.value);
                e.target.value = "";
              }
            }}
            sx={{ mb: 2 }}
          />
          <Box display="flex" gap={1} flexWrap="wrap">
            {searchItems.map((item) => (
              <Chip
                key={item}
                label={item}
                onDelete={() => handleRemoveTag(item)}
                color="primary"
              />
            ))}
          </Box>
          <Button variant="contained" onClick={fetchItems} sx={{ mt: 2 }}>
            Fetch Shopping List
          </Button>
        </CardContent>
      </Card>

      {/* Category Tabs */}
      <Tabs
        value={category}
        onChange={(e, newValue) => setCategory(newValue)}
        variant="scrollable"
        scrollButtons="auto"
      >
        {categories.map((cat) => (
          <Tab key={cat} label={cat} value={cat} />
        ))}
      </Tabs>

      {/* Items Table */}
      {renderItemsTable()}

      {/* Total Cost */}
      <Box display="flex" justifyContent="flex-end" mt={3}>
        <Typography variant="h6">Total Cost: ${totalCost.toFixed(2)}</Typography>
      </Box>
    </Box>
  );
};

export default CompareFlyers;
