import { jwtDecode } from "jwt-decode";

// Utility function to check if the token is expired
export const isTokenExpired = (token) => {
  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Current time in seconds
    return decoded.exp < currentTime;
  } catch (err) {
    return true; // If token is invalid or cannot be decoded, consider it expired
  }
};
