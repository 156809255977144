import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserContext } from './context/UserContext';  // Import UserContext
import TopBar from './TopBar';  
import { isTokenExpired } from '../utils/isTokenExpired';


const ProtectedRoutes = () => {
    const token = localStorage.getItem("token")

    // Only check for user once loading is complete
    if (!token || isTokenExpired(token)) {
        localStorage.removeItem("token"); // Clear expired token
        return <Navigate to="/login" replace />; // Redirect to login
      }

    // If user is authenticated, render the protected content
    return (
        <>
            <TopBar />  {/* TopBar will appear on all protected routes */}
            <Outlet />  {/* Outlet renders the nested/protected components */}
        </>
    );
};

export default ProtectedRoutes;
