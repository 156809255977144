import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
} from "@mui/material";

const Modal = ({ show, onClose, onSave, userEmail, setUserEmail }) => {
  if (!show) return null;

  return (
    <Dialog open={show} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h5">Email Shopping List</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Enter an email to send the shopping list and close this list to start
          a new shopping list.
        </Typography>
        <TextField
          fullWidth
          label="Email Address"
          type="email"
          value={userEmail}
          onChange={(e) => setUserEmail(e.target.value)}
          placeholder="Enter your email"
          margin="normal"
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={onSave}
          color="primary"
          variant="contained"
          disabled={!userEmail}
        >
          Email & Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
