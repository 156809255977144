import React, { useState } from 'react';
import { TextField, Button, Typography, Box, CircularProgress } from '@mui/material';
import axiosInstance from '../components/BasePath/axiosInstance';
import { useNavigate } from 'react-router-dom';

const ForgetPassword = () => {
  const [email, setEmail] = useState('');
  const [load,setload]=useState(false);

  const navigate = useNavigate();
const handlereset = async()=>{
  setload(true)
    const res = await axiosInstance.post('/auth/forgetpassword',{email});
    setload(false);
    if(res?.data?.success)
    {
        navigate('/linksent')
    }
}

  return (
    <Box sx={{ maxWidth: 400, mx: 'auto', mt: 5, p: 3, boxShadow: 3, borderRadius: 2 }}>
      <Typography variant="h5" align="center" gutterBottom>
        Reset Password
      </Typography>
      <TextField
        fullWidth
        label="Email"
        variant="outlined"
        margin="normal"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
    {load?<CircularProgress/>:
    <Button fullWidth onClick={handlereset} variant="contained"  color="primary" sx={{ mt: 2 }}>
    Reset Password
  </Button>}
      
    </Box>
  );
};

export default ForgetPassword;
