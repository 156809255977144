import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Card, CardMedia, CardContent, Typography, CircularProgress, TextField, Stack } from '@mui/material';
import axiosInstance from './BasePath/axiosInstance';

const BrowseFlyers = () => {
  const [flyers, setFlyers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [code, setCode] = useState(''); // Postal code state
  const [provinceFilter, setProvinceFilter] = useState(''); // Province filter based on postal code

  useEffect(() => {
    const fetchFlyers = async () => {
      try {
        const response = await axiosInstance.get('/flyers'); // Fetch data from backend
        setFlyers(response?.data || []); // Set data to state
      } catch (error) {
        console.error('Error fetching flyers:', error);
      } finally {
        setLoading(false); // Set loading to false after fetching data
      }
    };

    fetchFlyers();
  }, []);

  // Function to get the province from the postal code
  function getProvinceFromPostalCode(postalCode) {
    const provinceMap = {
      'A': 'Newfoundland and Labrador',
      'B': 'Nova Scotia',
      'C': 'Prince Edward Island',
      'E': 'New Brunswick',
      'G': 'Quebec',
      'H': 'Quebec',
      'J': 'Quebec',
      'K': 'Ontario',
      'L': 'Ontario',
      'M': 'Ontario',
      'N': 'Ontario',
      'P': 'Ontario',
      'R': 'Manitoba',
      'S': 'Saskatchewan',
      'T': 'Alberta',
      'V': 'British Columbia',
      'X': 'Northwest Territories / Nunavut',
      'Y': 'Yukon'
    };
  
    if (!postalCode || postalCode.length < 1) {
      return "Invalid postal code";
    }

    const firstLetter = postalCode.charAt(0).toUpperCase();
    return provinceMap[firstLetter] || "Unknown province/territory";
  }
  useEffect(() => {
    if (code) {
      const province = getProvinceFromPostalCode(code);
      console.log(province)
      setProvinceFilter(province); // Set the province filter based on the postal code
    } else {
      setProvinceFilter(''); // If search box is cleared, reset the province filter
    }
  }, [code]);

  // Filter flyers based on province and active status
  const filteredFlyers = flyers.filter(
    (flyer) =>
      flyer.active &&
      (provinceFilter ? flyer.province === provinceFilter : true) // If provinceFilter is set, filter by province, else show all
  );

  return (
    <Box sx={{ px: 2 }}>
      <Typography variant="h4" textAlign="center" mb={4} fontWeight="bold">
        Browse Flyers
      </Typography>
      <Stack my={2}>
        <TextField
          sx={{ width: 'fit-content' }}
          value={code}
          onChange={(e) => setCode(e.target.value)}
          label="Search postal code"
        />
      </Stack>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
          <CircularProgress />
        </Box>
      ) : filteredFlyers.length > 0 ? (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 3,
            justifyContent: 'center',
          }}
        >
          {filteredFlyers.map((flyer) => (
            <Link
              to={`/flyers/${flyer.merchantId}`}
              key={flyer.merchantId}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                  borderRadius: 2,
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                  maxWidth: 300,
                  flex: '1 1 calc(25% - 24px)', // Flexible width for responsiveness
                }}
              >
                <CardMedia
                  component="img"
                  image={
                    flyer.logoUrl
                      ? flyer.logoUrl
                      : 'https://via.placeholder.com/300'
                  }
                  alt={flyer.merchantName}
                  sx={{ aspectRatio: '16 / 9', objectFit: 'cover' }} // Maintain responsive image ratio
                />
                <CardContent>
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    {flyer.merchantName}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {flyer.lastDownloaded &&
                    !isNaN(new Date(flyer.lastDownloaded).getTime())
                      ? `Last Downloaded: ${new Date(flyer.lastDownloaded).toLocaleDateString()}`
                      : 'Never Downloaded'}
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          ))}
        </Box>
      ) : (
        <Typography variant="h6" textAlign="center" mt={4}>
          No flyers available.
        </Typography>
      )}
    </Box>
  );
};

export default BrowseFlyers;
