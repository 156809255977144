import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "./context/UserContext";
import MealPlanDisplay from "./MealPlanDisplay";
import SaveNewMealModal from "./modals/SaveNewMeal";
import axiosInstance from "./BasePath/axiosInstance";

import {
  Grid,
  Typography,
  Button,
  Paper,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
  Alert,
  Snackbar,
  Box,
} from "@mui/material";

const MealPlanner = () => {
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [gptLoading, setGptLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [mealPlans, setMealPlans] = useState([]);
  const [generatedMeals, setGeneratedMeals] = useState(null);
  const [generationTime, setGenerationTime] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedAI, setSelectedAI] = useState("ChatGPT");
  const [showModal, setShowModal] = useState(false);
  const [planName, setPlanName] = useState("");
  const [selectedMealPlan, setSelectedMealPlan] = useState(null);

  // Group ingredients by merchant
  const groupIngredientsByMerchant = (recipes) => {
    const groupedIngredients = {};
    recipes?.forEach((recipe) => {
      recipe.ingredients.forEach((ingredient) => {
        const { merchantName, itemName, itemPrice } = ingredient;
        if (!groupedIngredients[merchantName]) {
          groupedIngredients[merchantName] = [];
        }
        groupedIngredients[merchantName].push({ itemName, itemPrice });
      });
    });
    return groupedIngredients;
  };

  // Fetch meal plans on component mount
  useEffect(() => {
    const fetchMealPlans = async () => {
      setIsLoading(true);
      try {
        const res = await axiosInstance.get("/meal/plans");
        setMealPlans(res.data.mealPlans || []);
      } catch (error) {
        setErrorMessage("Failed to fetch meal plans.");
      } finally {
        setIsLoading(false);
      }
    };
    fetchMealPlans();
  }, [user]);

  // Generate new meal plan via GPT
  const generateMealPlan = async () => {
    setGptLoading(true);
    const startTime = new Date();
    try {
      const res = await axiosInstance.post("/meal/planner", { aiModel: selectedAI });
      setGeneratedMeals(res.data.meals);
      setSelectedMealPlan(null);
      const endTime = new Date();
      setGenerationTime(((endTime - startTime) / 1000).toFixed(2));
    } catch (error) {
      setErrorMessage(`Failed to generate meal plan: ${error.response?.data.message || error.message}`);
    } finally {
      setGptLoading(false);
    }
  };

  // Save the generated meal plan
  const saveMealPlan = async () => {
    setIsSaving(true);
    try {
      const res = await axiosInstance.post("/meal/plans", {
        mealPlan: { name: planName, recipes: generatedMeals.recipes },
      });
      setMealPlans([...mealPlans, res.data.mealPlan]);
      setGeneratedMeals(null);
      setShowModal(false);
      setPlanName("");
      setSuccess(`Meal Plan "${planName}" saved successfully!`);
    } catch (error) {
      setErrorMessage("Failed to save meal plan.");
    } finally {
      setIsSaving(false);
    }
  };

  // Load selected meal plan
  const handleMealPlanClick = async (mealPlan) => {
    try {
      const res = await axiosInstance.get(`/meal/plans/${mealPlan._id}`);
      setSelectedMealPlan(res.data.mealPlan);
      setGeneratedMeals(null);
    } catch (error) {
      setErrorMessage("Failed to retrieve meal plan details.");
    }
  };

  return (
    <Box sx={{ p: 3,mt:6 }}>
      <Typography variant="h4" gutterBottom>
        Meal Planner
      </Typography>

      <Grid container spacing={3}>
        {/* Meal Plans Section */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Saved Meal Plans
            </Typography>
            {isLoading ? (
              <CircularProgress />
            ) : mealPlans.length === 0 ? (
              <Typography>No saved meal plans. Generate a new one!</Typography>
            ) : (
              mealPlans.map((plan) => (
                <Box key={plan._id} sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
                  <Button
                    variant="outlined"
                    onClick={() => handleMealPlanClick(plan)}
                    sx={{ textTransform: "none" }}
                  >
                    {plan.name} - {new Date(plan.date).toLocaleDateString()}
                  </Button>
                </Box>
              ))
            )}
          </Paper>
        </Grid>

        {/* Generated Meal Plan Section */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
            {selectedMealPlan ? (
              <MealPlanDisplay
                mealPlan={selectedMealPlan}
                groceryList={groupIngredientsByMerchant(selectedMealPlan.recipes)}
              />
            ) : generatedMeals ? (
              <MealPlanDisplay
                mealPlan={generatedMeals}
                groceryList={groupIngredientsByMerchant(generatedMeals.recipes)}
              />
            ) : (
              <Typography>No meal plan selected or generated.</Typography>
            )}
          </Paper>
        </Grid>

        {/* Actions Section */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <FormControl>
                <InputLabel>Choose AI Model</InputLabel>
                <Select
                  value={selectedAI}
                  onChange={(e) => setSelectedAI(e.target.value)}
                  sx={{ minWidth: 200 }}
                >
                  <MenuItem value="ChatGPT">ChatGPT</MenuItem>
                  <MenuItem value="ClaudeAI">ClaudeAI</MenuItem>
                </Select>
              </FormControl>
              <Button
                variant="contained"
                onClick={generateMealPlan}
                disabled={gptLoading}
                startIcon={gptLoading ? <CircularProgress size={20} /> : null}
              >
                {gptLoading ? "Generating..." : "Generate New Meal Plan"}
              </Button>
              {generatedMeals && (
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => setShowModal(true)}
                  disabled={isSaving}
                >
                  {isSaving ? "Saving..." : "Save Meal Plan"}
                </Button>
              )}
            </Box>
            {generationTime && (
              <Typography sx={{ mt: 2 }}>Meal plan generated in {generationTime} seconds.</Typography>
            )}
          </Paper>
        </Grid>
      </Grid>

      {/* Success/Error Notifications */}
      <Snackbar
        open={!!success}
        autoHideDuration={4000}
        onClose={() => setSuccess("")}
        message={success}
      />
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

      {/* Modal for Saving Meal Plan */}
      <SaveNewMealModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onSave={saveMealPlan}
        planName={planName}
        setPlanName={setPlanName}
      />
    </Box>
  );
};

export default MealPlanner;
