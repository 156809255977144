import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "./context/UserContext";
import { ShoppingContext } from "./context/ShoppingContext";
import { Link } from "react-router-dom";
import ModalClose from "./modals/CloseShoppingList";
import ModalAdd from "./modals/AddShoppingList";
import axiosInstance from "./BasePath/axiosInstance";

import {
  Typography,
  Button,
  Paper,
  Grid,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
  Box,
  TextField,
  Alert,
  Snackbar,
} from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

const ShoppingList = () => {
  const { user } = useContext(UserContext);
  const {
    shoppingList,
    removeItemFromShoppingList,
    fetchShoppingList,
    groupItemsByMerchant,
    formatShoppingListForEmail,
  } = useContext(ShoppingContext);

  const [totalPrice, setTotalPrice] = useState(0);
  const [email, setEmail] = useState("");
  const [showModalClose, setShowModalClose] = useState(false);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [success, setSuccess] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [addedItems, setAddedItems] = useState(new Set());

  // Set user email
  useEffect(() => {
    if (user) setEmail(user.email);
  }, [user]);

  // Calculate total price from the shopping list
  useEffect(() => {
    if (shoppingList && Array.isArray(shoppingList)) {
      const total = shoppingList.reduce((sum, item) => {
        if (!item || !item.itemPrice) return sum;
        const price = typeof item.itemPrice === "number"
          ? item.itemPrice
          : parseFloat(item.itemPrice.replace(/[^0-9.]/g, "")) || 0;
        return sum + price;
      }, 0);
      setTotalPrice(total);
    }
  }, [shoppingList]);

  // Live search functionality
  useEffect(() => {
    const performSearch = async () => {
      if (searchTerm.length >= 2) {
        try {
          const response = await axiosInstance.get(`/search/items?query=${searchTerm}`);
          setSearchResults(response.data.results);
        } catch (error) {
          console.error("Error performing search:", error);
        }
      } else {
        setSearchResults([]);
      }
    };
    performSearch();
  }, [searchTerm]);

  // Handle removing an item
  const handleRemoveItem = async (merchantName, item) => {
    try {
      await removeItemFromShoppingList(merchantName, item);
      setAddedItems((prevSet) => {
        const updatedSet = new Set(prevSet);
        updatedSet.delete(item.itemName);
        return updatedSet;
      });
      await fetchShoppingList();
    } catch (error) {
      console.error("Error removing item from shopping list:", error);
    }
  };

  // Handle closing the shopping list and sending email
  const handleCloseList = async () => {
    try {
      await axiosInstance.post("/shopping/close", {
        email,
        shoppingListHtml: formatShoppingListForEmail(shoppingList),
      });
      setSuccess("Shopping List email sent! Ready for a new Shopping List.");
      setShowModalClose(false);
      await fetchShoppingList();
    } catch (error) {
      console.error("Error closing shopping list:", error);
    }
  };

  return (
    <Box sx={{ p: 3,mt:6 }}>
      <Typography variant="h4" gutterBottom>
        Shopping List
      </Typography>
      <Grid container spacing={3}>
        {/* Shopping List */}
        <Grid item xs={12} md={8}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Your Shopping List
            </Typography>
            {shoppingList.length === 0 ? (
              <Typography>No items in the shopping list.</Typography>
            ) : (
              groupItemsByMerchant(shoppingList).map((merchantGroup, index) => (
                <Box key={index} sx={{ mb: 3 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    {merchantGroup.merchantName}
                  </Typography>
                  <List>
                    {merchantGroup.items.map((item, i) => (
                      <ListItem key={i} secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="remove"
                          onClick={() => handleRemoveItem(merchantGroup.merchantName, item)}
                        >
                          <RemoveCircleIcon color="error" />
                        </IconButton>
                      }>
                        <ListItemText
                          primary={`${item.itemName}`}
                          secondary={`Price: $${item.itemPrice}`}
                        />
                      </ListItem>
                    ))}
                  </List>
                  <Divider />
                </Box>
              ))
            )}
          </Paper>
        </Grid>

        {/* Actions */}
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Actions
            </Typography>
            <TextField
              label="Search Items"
              fullWidth
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{ mb: 2 }}
            />
            <Button
              variant="contained"
              fullWidth
              onClick={() => setShowModalAdd(true)}
              sx={{ mb: 2 }}
            >
              Add Items
            </Button>
            <Button
              variant="contained"
              color="success"
              fullWidth
              onClick={() => setShowModalClose(true)}
              disabled={shoppingList.length === 0}
              sx={{ mb: 2 }}
            >
              Email Shopping List
            </Button>
            <Typography variant="subtitle1" gutterBottom>
              Total Price: ${totalPrice.toFixed(2)}
            </Typography>
            <Link to="/shopping-archive" style={{ textDecoration: "none" }}>
              <Button variant="outlined" fullWidth>
                Review Archived Shopping Lists
              </Button>
            </Link>
          </Paper>
        </Grid>
      </Grid>

      {/* Success/Failure Messages */}
      <Snackbar
        open={!!success}
        autoHideDuration={4000}
        onClose={() => setSuccess("")}
        message={success}
      />

      {/* Modals */}
      <ModalClose
        show={showModalClose}
        onClose={() => setShowModalClose(false)}
        onSave={handleCloseList}
        userEmail={email}
      />
      <ModalAdd
        show={showModalAdd}
        onClose={() => setShowModalAdd(false)}
        search={searchTerm}
        setSearch={setSearchTerm}
        searchResults={searchResults}
      />
    </Box>
  );
};

export default ShoppingList;
