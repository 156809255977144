import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { UserContext } from './context/UserContext';
import './css/Login.css';
import axiosInstance from './BasePath/axiosInstance';
import { Button, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const validatePassword = require('../utils/validatePassword');

const ConfirmEmail = () => {
    const [status, setStatus] = useState(null);  // Track success/failure of confirmation
    const [fullName, setFullName] = useState('');  // Full name input field
    const [password, setPassword] = useState('');  // Password input for new password
    const [passwordError, setPasswordError] = useState('');  // To handle password validation errors
    const [showPassword, setShowPassword] = useState(false);  // To toggle password visibility
    const navigate = useNavigate();  // For programmatic navigation after login
 
    
    const [timeLeft, setTimeLeft] = useState(120); // 120 seconds = 2 minutes
    const [isActive, setIsActive] = useState(false);

    const {email}=useParams();


    const SendOtp1 = async () => {
        try {
            const res = await axiosInstance.post('/auth/signup', {
                email,
              });
              if (res?.data?.status) {
                startTimer();
                setIsActive(true);
              }
        } catch (error) {
          console.error('Error during signup:', error);
        }
      };

    useEffect(() => {
      let timer;
      if (isActive && timeLeft > 0) {
        timer = setInterval(() => {
          setTimeLeft((prevTime) => prevTime - 1);
        }, 1000);
      } else if (timeLeft === 0) {
        setIsActive(false);
      }
      return () => clearInterval(timer);
    }, [isActive, timeLeft]);
  
  
  
    const [otp, setOtp] = useState(['', '', '', '']);
    const inputRefs = useRef([]);
  
    const handleChange = (index, event) => {
      const value = event.target.value;
      if (!/^[0-9]?$/.test(value)) return;
      
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      
      if (value && index < 3) {
        inputRefs.current[index + 1].focus();
      }
    };
  
    const handleKeyDown = (index, event) => {
      if (event.key === 'Backspace' && !otp[index] && index > 0) {
        inputRefs.current[index - 1].focus();
      }
    };
  
  
  
    const startTimer = () => {
      setTimeLeft(120);
      setIsActive(true);
    };
  
    const formatTime = (seconds) => {
      const minutes = Math.floor(seconds / 60);
      const secs = seconds % 60;
      return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };


    // Access the setUser function from UserContext
    const { setUser } = useContext(UserContext);  // Use setUser to update the session

const handleverify = async()=>{
    try {
const oTp=otp.join('');
        const response = await axiosInstance.post(`/auth/verify-otp`,{email,"otp":oTp});

        if (response?.data?.status) {
            setStatus('success');  // Set status to 'success' on successful confirmation
        } else {
            setStatus('error');  // Set status to 'error' on failure
        }
    } catch (error) {
        setStatus('error');  // Handle network or unexpected errors
    }
}

    useEffect(() => {
        startTimer();
    }, []);

    const handleSetDetails = async (e) => {
        e.preventDefault();

        // Validate password
        const passwordValidationError = validatePassword(password);
        if (passwordValidationError) {
            setPasswordError(passwordValidationError);
            return;
        }

        // Proceed only if fullName and password are entered and valid
        if (!fullName.trim()) {
            setPasswordError('Full name is required');
            return;
        }

        try {
            const response = await axiosInstance.post('/auth/set-registration', { email, password, fullName })

            if (response?.data?.status) {

                // Update the UserContext with the fullName and token
                setUser({
                    fullName: fullName,  // Set the full name in the session
                    email: response?.data.email,  // Assuming the backend sends the email as well
                    avatar: response?.data.avatar || 'https://via.placeholder.com/150',  // Default avatar
                });

                // Redirect to the home page after login
                navigate('/');
            } else {
                setPasswordError(response?.data?.message || 'Failed to set password');
            }
        } catch (error) {
            setPasswordError('An error occurred while setting the password. Please try again.');
        }
    };



    return (
        <>
        {status==="success"?<div className="login-container">
            <Typography variant='h3'>Complete Registration</Typography>

          
            <Stack spacing={2} sx={{ maxWidth: 400, mx: 'auto', textAlign: 'center' }}>
        <Typography variant="h5" fontWeight="bold">
            Almost done!
        </Typography>
        <Typography variant="body1">
            Enter your full name and set a password to continue.
        </Typography>

        <form onSubmit={handleSetDetails}>
            <Stack spacing={2}>
                <TextField
                    fullWidth
                    label="Full Name"
                    variant="outlined"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    required
                />

                <TextField
                    fullWidth
                    label="Set your password"
                    variant="outlined"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => {
                        setPassword(e.target.value);
                        setPasswordError('');
                    }}
                    required
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />

                {passwordError && (
                    <Typography variant="body2" color="error">
                        {passwordError}
                    </Typography>
                )}

                <Button type="submit" variant="contained" color="primary" fullWidth>
                    Continue
                </Button>
            </Stack>
        </form>
    </Stack>
           

        </div>: 
        <Stack mt={8} >
            <Typography my={2}>Send OTP!</Typography>
        <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
    {otp.map((digit, index) => (
      <input
      key={index}
      type="text"
      value={digit}
      maxLength={1}
      onChange={(e) => handleChange(index, e)}
      onKeyDown={(e) => handleKeyDown(index, e)}
        ref={(el) => (inputRefs.current[index] = el)}
        style={{
          width: '50px',
          height: '50px',
          textAlign: 'center',
          fontSize: '20px',
          border: '1px solid #ccc',
          borderRadius: '5px'
        }}
        />
      ))}
      <Stack justifyContent={'end'}>
        <Typography>{formatTime(timeLeft)}</Typography>
      </Stack>
  </div>
  <Stack alignItems={'center'} mt={2}>
    {isActive?
        <Button sx={{width:'fit-content'}}  variant='contained' onClick={handleverify}>Verify OTP</Button>
        :
        <Button sx={{width:'fit-content'}}  variant='contained' onClick={SendOtp1}>Resend OTP</Button>

    }
  </Stack>
      </Stack>
        }
       
        </>
    );
};

export default ConfirmEmail;
